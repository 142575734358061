<template>
    <div class="pop-up-container" v-show="open">
        <div>
            <div class="content-wrapper">
                <div class="topbar">
                </div>
                <div class="claim-circle">
                    <IconsError class="claim-icon"></IconsError>
                </div>
                <div class="header">
                    <label v-if="title">{{ title }}</label>
                </div>

                <div class="content">
                    <slot>CONTENT</slot>
                </div>

                <slot name="actions">
                    <div class="actions">
                        <button class="secondary" @click="handleCancel">
                            <label>{{ cancelLabel }}</label>
                        </button>
                        <button class="primary" @click="handleSubmit">
                            <label>{{ submitLabel }}</label>
                        </button>
                    </div>
                </slot>
            </div>
        </div>
    </div>
</template>

<script>
const EMIT_CLOSE = 'close'

export default {
    name: 'pop-up',
    emits: [ EMIT_CLOSE ],
    props: {
        open: {
            type: Boolean,
            required: true,
            default: false
        },
        title: {
            type: String,
            default: ''
        },
        submitLabel: {
            type: String,
            default: 'Submit'
        },
        cancelLabel: {
            type: String,
            default: 'Cancel'
        }
    },
    methods: {
        handleSubmit() {
            this.$emit(EMIT_CLOSE, true)
        },
        handleCancel() {
            this.$emit(EMIT_CLOSE, false)
        }
    }

}
</script>

<style lang="scss" scoped>
.pop-up-container {
    z-index: 50;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000aa;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;

    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: var(--sys-scrim);

    .content-wrapper {
        width: 280px;
        padding-bottom: 20px;
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;
        border-bottom-left-radius: 18px;
        border-bottom-right-radius: 18px;      
        background-color: var(--sys-surface);

        > .topbar {
            height: 50px;
            border-top-left-radius: 18px;
            border-top-right-radius: 18px;
            background-color: var(--sys-primary);
        }
        > .claim-circle {
            margin: auto;
            position: relative;
            top: -25px;
            width: 49px;
            height: 49px;
            border-radius: 25px;
            border: solid 3px var(--sys-on-primary);
            background-color: var(--sys-primary);
            text-align: center;
            color: var(--sys-on-primary);

            > .claim-icon{
                margin-top:12px;
                width: 20px;
                height: 20px;
            }
        }

        > .header {
            width: 100%;
            margin-top: -12px;
            color: var(--sys-on-surface);
            text-align: center;

            > label {
                font-size: 22px;
                font-weight: 600;
            }
        }

        > .content {
            padding: 16px;
            font-size: 14px;
            color: var(--sys-on-surface);
        }

        > .actions {
            display: flex;
            gap: 20px;
            margin: 12px 32px 0px 32px;
            > button{
                margin: 0px;
            }
        }
    }
}
</style>